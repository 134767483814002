import React, { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { TRANSACTIONS } from "../utils/apis";
import fetcher from "../utils/fetcher";
import { ThreeDots } from "react-loader-spinner";
import { Routes, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableBody,
  Datepicker,
  Toggle,
  ToggleItem,
  Card,
  AreaChart,
} from "@tremor/react";
import BvnVerification from "./apisegments/bvn";
import VotersVerification from "./apisegments/voters";
import DlVerification from "./apisegments/drivers";
import Tin from "./apisegments/tin";
import Vnin from "./apisegments/vnin";
import ObjectDetection from "./apisegments/objectDetection";
import WasteDetection from "./apisegments/wasteDetection";
import FoodDetection from "./apisegments/foodDetection";
import GenderDetection from "./apisegments/genderDetection";
import EmotionDetection from "./apisegments/emotionDetection";
import LicensePlateDetection from "./apisegments/licensePlateDetection";

function batchIntoYear(transactions) {
  const batches = {};
  const modifiedTransactions = transactions.map((t) =>
    new Date(t.createdAt).toLocaleDateString("en-CA")
  );
  for (const iterator of modifiedTransactions) {
    if (batches[iterator]) {
      batches[iterator] = batches[iterator] + 1;
    } else {
      batches[iterator] = 1;
    }
  }
  return Object.entries(batches);
}

const tabs = [
  { key: "overview", text: "Overview" },
  { key: "apiUsage", text: "API usage" },
  { key: "playground", text: "Playground" },
  { key: "documentation", text: "Documentation" },
];

export default function Idntity() {
  return (
    <div className="container  p-5">
      <h2 className="has-text-weight-bold">IDntity</h2>
      <div className="slider flex flex-col md:flex-row">
        {tabs.map((t) => (
          <NavLink
            to={`/app/idntity/${t.key}`}
            className={({ isActive }) =>
              isActive ? `slide-item scroller` : "slide-item"
            }
            key={t.key}
          >
            {t.text}
          </NavLink>
        ))}
      </div>

      <div className="houser">
        <Routes>
          <Route path="/apiUsage" element={<History />} />
          <Route path="/playground" element={<ObjectDetails />} />
          <Route path="/documentation" element={<Docs />} />
          <Route path="/*" exact element={<Profile />} />
        </Routes>
      </div>
      <style jsx="true">
        {`
          .houser {
            background-color: #fff;
            border-radius: 20px;
            padding: 2rem;
          }

          .greeting {
            font-weight: 800;
          }
          h4.greeting {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            font-size: 0.8rem;
            text-transform: uppercase;
            font-weight: 300;
          }
          .slider {
            margin: 1rem 0px;
            display: flex;
            position: relative;
          }

          .slide-item {
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: transparent;
            position: relative;
            z-index: 10;
            color: #0047ff;
            font-weight: bold;
            cursor: pointer;
            flex-basis: 150px;
            flex-shrink: 0;
          }

          .slide-item.scroller {
            background-color: #fff;

            border-radius: 10px;
          }
          @media screen and (max-width: 768px) {
            .slide-item {
              text-align: start;
              flex-basis: 50px;
            }
          }
        `}
      </style>
    </div>
  );
}

function Profile() {
  return (
    <div>
      <div className="second mb-5">
        <div className="second-first mb-5">
          IDntity is [AI]’s identity verification and object detection machine
          learning service delivered through an API. The system is powered by
          machine learning algorithms trained and managed as a service in the
          [AI] Platform and delivered through Powered by [AI]. The IDntity API
          delivered as a web service can be integrated in new or existing
          solutions as part of a data pipeline for identity verification or
          object detection in any application.
          <br />
          <br />
          The solution has many use cases from the identification and
          verification of IDs for onboarding customers, to feeding in live video
          footage for automated surveillance or security monitoring. The IDntity
          API lets developers verify customers identification documents for
          security or customer onboarding needs and automated near real-time KYC
          checks. The IDntity also gives developers machine learning
          intelligence that can be connected to any camera to automatically
          detect and identify objects such as road vehicles, license plates and
          a wide variety of objects depending on the need.
        </div>
        <div className="second-second mb-5">
          <img
            src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669137156/Frame_11864_wewubm.png"
            alt="frame"
          />
        </div>
      </div>
      <style jsx="true">
        {`
          .second {
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
          }
          .second-first {
            flex-basis: 450px;
            margin-right: 4rem;
          }

          @media only screen and (max-width: 850px) {
            .second {
              flex-wrap: wrap;
            }
            .second-first {
              flex-grow: 1;
              margin-right: 0px;
            }
            .second-second {
              flex-grow: 1;
            }
            .reverse {
              flex-direction: column-reverse;
            }
            .reverse .second-first {
              flex: 1;
            }
          }

          .second {
            color: rgb(61, 61, 61, 0.7);
            line-height: 1.7;
          }
        `}
      </style>
    </div>
  );
}

function History() {
  const { token } = useAuth();
  const [start, setStart] = useState(new Date(new Date().getFullYear() - 1));
  const [end, setEnd] = useState(new Date());

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentView, setCurrentView] = useState("history");

  useEffect(() => {
    const fetchTransactions = () => {
      setLoading(true);
      // Format start and end dates to local date strings without timezone offset
      const formattedStart = new Date(start.getTime() - start.getTimezoneOffset() * 60000).toISOString();
      const formattedEnd = new Date(end.getTime() - end.getTimezoneOffset() * 60000).toISOString();

      fetcher(
        `${TRANSACTIONS}?page=${page}&start=${formattedStart}&end=${formattedEnd}`,
        {
          method: "GET",
          headers: {
            "X-API-KEY": `${token}`,
            "Content-Type": "application/json",
          },
        }
      ).then(({ transactions }) => {
        if (transactions) {
          setData(transactions);
        }
      })
        .catch(console.log)
        .finally(() => setLoading(false));
    };
    fetchTransactions();
  }, [page, setData, setLoading, start, end, token]);

  return (
    <div className="mb-6">
      <Datepicker
        placeholder="Select..."
        enableRelativeDates={true}
        handleSelect={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        defaultStartDate={start}
        defaultEndDate={end}
        defaultRelativeFilterOption={null}
        minDate={null}
        maxDate={end}
        color="blue"
        maxWidth="max-w-xs"
        marginTop="mt-0"
      />
      <Toggle
        defaultValue={"history"}
        handleSelect={setCurrentView}
        color="blue"
        marginTop="mt-5"
      >
        <ToggleItem value={"history"} text="History" icon={undefined} />
        <ToggleItem value={"chart"} text="Chart" icon={undefined} />
      </Toggle>
      {loading ? (
        <div className="center">
          <ThreeDots
            height="100"
            width="200"
            radius="9"
            color="#334d8c"
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </div>
      ) : (
        <>
          {currentView === "history" ? (
            <Card marginTop="mt-6">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>TYPE</TableHeaderCell>
                    <TableHeaderCell>DATE TIME</TableHeaderCell>
                    <TableHeaderCell>SERVICES</TableHeaderCell>
                    <TableHeaderCell>COST</TableHeaderCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.map((item) => (
                    <TableRow key={item._id}>
                      <TableCell>IDntity</TableCell>
                      <TableCell>
                        {formatDate(new Date(item.createdAt))}
                      </TableCell>
                      <TableCell>{item.service}</TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("en-NG", {
                          style: "currency",
                          currency: "NGN",
                        }).format(Number(item.amount))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          ) : (
            <AreaChart
              marginTop="mt-8"
              data={batchIntoYear(data)
                .map((d) => ({
                  date: d[0],
                  count: d[1],
                }))
                .reverse()}
              categories={["count"]}
              dataKey="date"
              colors={["indigo"]}
              showLegend={false}
              height="h-80"
            />
          )}
        </>
      )}
      <div
        className="field has-addons mt-5 mr-5"
        style={{ justifyContent: "flex-end" }}
      >
        <p className="control previous">
          <button
            className="button"
            disabled={Number(page) === 1}
            onClick={() => {
              setPage((p) => Math.max(p - 1, 1));
            }}
          >
            <span className="icon is-small">
              <i className="fa-solid fa-angle-left"></i>
            </span>
            <span>Previous</span>
          </button>
        </p>

        <p className="control next">
          <button
            className="button"
            disabled={data.length < 10}
            onClick={() => {
              if (data.length >= 10) {
                setPage((p) => p + 1);
              }
            }}
          >
            <span>Next</span>
            <span className="icon is-small">
              <i className="fa-solid fa-angle-right"></i>
            </span>
          </button>
        </p>
      </div>
      <style jsx="true">
        {`
          .previous button,
          .next button {
            background-color: transparent;
            border: 0px;
            outline: 0px;
            color: var(--main-hue);
          }
          .transaction-data {
            padding-bottom: 1.5rem;
          }
          @media (max-width: 800px) {
            .transaction-data {
              overflow-x: scroll;
            }
          }

          .center {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
          }
        `}
      </style>
    </div>
  );
}

function ObjectDetails() {
  const [state, setState] = useState("info");
  if (state === "info") {
    return (
      <div className="products">
        <div className="product">
          <div>
            <h5>Object detection</h5>
            <p className="mb-3">
              IDntity delivers algorithms driven by data and machine learning.
              The solution has many use cases from the identification and
              verification from still images for onboarding customers, to
              feeding in live video footage for automated surveillance or
              security monitoring.
            </p>
            <a onClick={() => setState("object")}>
              <button
                className="spread-button"
                style={{
                  color: "#0066ff",
                  backgroundColor: "#fff",
                }}
              >
                Enter playground
              </button>
            </a>
          </div>
          <img
            src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669057132/Group_11743_1_icphem.png"
            alt="guards"
            className="guards"
          />
        </div>
        <style jsx="true">
          {`
            .product {
              padding: 2rem;
              border-radius: 20px;
              color: #fff;
              background-image: linear-gradient(to right, #0066ff, #0027b2);
              background-size: cover;
              background-repeat: no-repeat;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 40px;
              flex-wrap: wrap;
              margin-top: 40px;
            }

            .product .guards {
              position: absolute;
              right: 40px;
              flex-basis: 300px;
            }
            @media only screen and (max-width: 960px) {
              .product {
                margin-top: 0px;
              }
              .product .guards {
                display: none;
              }
            }
            .product h5 {
              font-weight: 900;
              margin-bottom: 1.5rem;
              text-transform: capitalize;
            }
            .product p {
              font-size: 1rem;
              color: rgba(255, 255, 255, 0.7);
              max-width: 450px;
              line-height: 1.7;
            }
            .product a {
              color: var(--main-hue);
              display: inline-block;
              margin-top: 1rem;
            }
          `}
        </style>
      </div>
    );
  }
  if (state === "object") {
    return <ObjectPlayground setState={setState} />;
  }
}
function ObjectPlayground({ setState }) {
  const [algorithms] = useState([
    { id: "bvn", text: "BVN Verification" },
    { id: "voter", text: "Voter's card Verification" },
    { id: "drivers", text: "Driver's license Verification" },
    { id: "tin", text: "Tax Identification Number" },
    { id: "vnin", text: "Virtual National Identity Number (vNIN)" },
    { id: "object_detection", text: "Object Detection" },
    { id: "waste_detection", text: "Waste Detection" },
    { id: "food_detection", text: "Food Detection" },
    { id: "gender_detection", text: "Gender Detection" },
    { id: "emotion_detection", text: "Emotion Detection" },
    { id: "license_detection", text: "License Plate Detection" },
  ]);
  const [currAlgorithm, setCurrAlgorithm] = useState(algorithms[0].id);
  return (
    <div>
      <a
        style={{
          marginBottom: "1rem",
          display: "inline-block",
          paddingLeft: ".5rem",
        }}
        onClick={() => {
          setState("info");
        }}
      >
        <i className="fa-solid fa-arrow-left"></i>{" "}
        <span style={{ marginLeft: ".5rem" }}>Go back</span>
      </a>
      <div className="field">
        <label className="label">Perform Lookup</label>
        <div className="control has-icons-right">
          <div className={`select`}>
            <select
              required
              name="algorithm"
              onChange={(e) => {
                setCurrAlgorithm(e.target.value);
              }}
            >
              {algorithms.map((a) => (
                <option key={a.id} value={a.id}>
                  {a.text}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {currAlgorithm === "bvn" ? <BvnVerification /> : null}
      {currAlgorithm === "voter" ? <VotersVerification /> : null}
      {currAlgorithm === "drivers" ? <DlVerification /> : null}
      {currAlgorithm === "tin" ? <Tin /> : null}
      {currAlgorithm === "vnin" ? <Vnin /> : null}
      {currAlgorithm === "object_detection" ? <ObjectDetection /> : null}
      {currAlgorithm === "waste_detection" ? <WasteDetection /> : null}
      {currAlgorithm === "food_detection" ? <FoodDetection /> : null}
      {currAlgorithm === "gender_detection" ? <GenderDetection /> : null}
      {currAlgorithm === "emotion_detection" ? <EmotionDetection /> : null}
      {currAlgorithm === "license_detection" ? <LicensePlateDetection /> : null}
    </div>
  );
}

function Docs() {
  return (
    <div className="contain">
      <a
        href="https://powerdbyai.readme.io/reference/detect-multiple-objects"
        className="prod"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669135512/Frame_11849_oqcl8j.png"
          alt="docs"
        />
      </a>
      <a
        target="_blank"
        href="https://powerdbyai.readme.io/reference/detect-multiple-objects"
        className="prod"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669135758/Frame_11850_zizwyi.png"
          alt="docs"
        />
      </a>
      <a
        href="https://powerdbyai.readme.io/reference/lookup-vnin"
        target="_blank"
        className="prod"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669135888/Frame_11851_e08vyw.png"
          alt="dcos"
        />
      </a>
      <a
        href="https://powerdbyai.readme.io/reference/lookup-drivers-license"
        className="prod"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669136026/Frame_11852_jqo1dn.png"
          alt="docs"
        />
      </a>
      <a
        href="https://powerdbyai.readme.io/reference/detect-multiple-objects"
        className="prod"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669136130/Frame_11853_qnijj6.png"
          alt="docs"
        />
      </a>
      <a
        href="https://powerdbyai.readme.io/reference/validate-bvn"
        className="prod"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1669136173/Frame_11854_phjpad.png"
          alt="docs"
        />
      </a>
      <a
        href="https://powerdbyai.readme.io/"
        target="_blank"
        className="mb-4"
        rel="noreferrer"
      >
        <button
          className="spread-button mt-4"
          style={{
            color: "#fff",
            backgroundColor: "#0066ff",
          }}
        >
          View documentation
        </button>
      </a>

      <style jsx="true">
        {`
          .board {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .prod img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
          .contain {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-auto-rows: minmax(50px, auto);
            grid-gap: 1rem;
            justify-content: space-between;
            margin-bottom: 1.5rem;
          }
        `}
      </style>
    </div>
  );
}

const formatDate = (date) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  // Extract the day and remove any suffix for 1st, 2nd, 3rd, etc.
  const day = date.getDate();
  const suffix =
    day >= 11 && day <= 13
      ? "th"
      : { 1: "st", 2: "nd", 3: "rd" }[day % 10] || "th";

  // Replace the day in the formatted date with the day and suffix
  const modifiedDate = formattedDate.replace(/\d{1,2}/, (day + suffix));

  return `${modifiedDate}, ${formatTime(date)}`;
};

const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
};

/*

 <option>Choose an algorithm</option>
              <option value="https://idntity.ai/img_detection">
                Object Detection
              </option>
              <option value="https://idntity.ai/gender_detection_img">
                Gender Detection
              </option>
              <option value="https://idntity.ai/license_reader">
                License Plate Detection
              </option>
              <option value="https://idntity.ai/emotion_detection">
                Emotion Detection
              </option>



*/
